import dialog from '@modules/dialog';
import swiper from '@modules/swiper';
import formieCsrf from '@modules/formieCsrf';
import gtm from '@modules/gtm';

// Layouts
import '@styles/layouts/l-site-footer.scss';
import '@styles/layouts/l-content-matrix.scss';
import '@styles/layouts/l-related-posts-list.scss';
import '@styles/layouts/l-downloads.scss';
import '@styles/layouts/l-general.scss';
import '@styles/layouts/l-form.scss';

// Components
import '@styles/components/c-skip-link.scss';
import '@styles/components/c-breadcrumbs.scss';
import '@styles/components/c-btn.scss';
import '@styles/components/c-text.scss';
import '@styles/components/c-social-post.scss';
import '@styles/components/c-copy.scss';
import '@styles/components/c-image.scss';
import '@styles/components/c-video.scss';
import '@styles/components/c-gallery.scss';
import '@styles/components/c-gallery-controls.scss';
import '@styles/components/c-cta.scss';
import '@styles/components/c-footer-nav.scss';
import '@styles/components/c-page-intro.scss';
import '@styles/components/c-category-list.scss';
import '@styles/components/c-category-intro.scss';
import '@styles/components/c-blog-post.scss';
import '@styles/components/c-contact-info.scss';
import '@styles/components/c-form.scss';
import '@styles/components/c-modal.scss';
import '@styles/components/c-downloads.scss';
import '@styles/components/c-notification-banner.scss';
import '@styles/components/c-cookie-table.scss';
import '@styles/components/c-split-hero.scss';
import '@styles/components/c-base-hero.scss';
import '@styles/components/c-related-blog-posts.scss';
import '@styles/components/c-completed-projects.scss';
import '@styles/overrides/formie.scss';

export default function init() {
  swiper();
  formieCsrf();
  gtm();
  dialog();
}

init();
